<script setup lang="ts">
import filter from "lodash/filter";
import AbandonedCart from "@/models/AbandonedCart";
import RegFlowStep from "@/models/RegFlowStep";
import { MAPPED_ROUTE_NAMES } from "@/constants/routes";
import { SIGNUP_USED_FLAG } from "@/constants/signup";

const onboardingStore = useOnboardingStore();
const route = useRoute();
const router = useRouter();
const shouldShowSignup = ref<string | boolean>(false);

const abandonedCart = computed(() => new AbandonedCart(onboardingStore.$state));

const steps = computed<RegFlowStep[]>(() => {
  const blockOnSignup = () => route.name !== MAPPED_ROUTE_NAMES.SIGNUP;

  return filter(
    [
      shouldShowSignup.value ? new RegFlowStep("signup", 0, () => true) : null,
      new RegFlowStep("plans", 1, blockOnSignup),
      new RegFlowStep("menu", 2, blockOnSignup),
      new RegFlowStep(
        "checkout",
        3,
        () =>
          (onboardingStore.isCheckoutStepAvailable ?? false) && blockOnSignup()
      ),
    ],
    Boolean
  ) as RegFlowStep[];
});

onMounted(() => {
  shouldShowSignup.value =
    window.localStorage.getItem(SIGNUP_USED_FLAG) ||
    route.name === MAPPED_ROUTE_NAMES.SIGNUP;
});
</script>
<template>
  <ul
    class="tw-flex tw-justify-center tw-items-center tw-gap-x-[5px] md:tw-gap-x-[10px] md:tw-gap-x-[50px] tw-w-[100%] md:tw-w-[85%]"
  >
    <li v-for="(step, index) in steps" :key="step.name">
      <a
        href="#"
        class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-0.5 tw-w-[88px]"
        @click.prevent="step.navigate(router)"
      >
        <span v-if="!step.isComplete(route, !!abandonedCart?.hasPlan())">
          <span
            class="tw-flex tw-items-center tw-justify-center tw-font-bold tw-h-[30px] tw-w-[30px] tw-rounded-full"
            :class="{
              'tw-bg-nl-green-400 tw-border-transparent tw-text-white':
                step.isCurrent(route),
              'tw-bg-transparent tw-border-nl-green-100 tw-text-nl-green-100':
                !step.isCurrent(route),
            }"
            :style="{ 'border-width': '3px' }"
          >
            {{ index + 1 }}
          </span>
        </span>
        <span v-else>
          <svg
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="15"
              cy="15"
              r="13.5"
              fill="#007D4D"
              stroke="#007D4D"
              stroke-width="3"
            ></circle>
            <path
              d="M10 16.7188C11.6667 18.3438 12.9167 22 14.5833 22C16.25 22 18.3333 11.8438 20 9"
              stroke="white"
              stroke-width="3"
              stroke-linecap="round"
            ></path>
          </svg>
        </span>
        <span
          :class="{
            'tw-font-bold': step.isCurrent(route),
          }"
          class="tw-uppercase"
        >
          {{ step.name }}
        </span>
      </a>
    </li>
  </ul>
</template>
